import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import Layout from "../components/layout/MainLayout";
import { WmkLink } from "wmk-link";

const MainWrap = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    max-width: 50rem;
  }
`;

export const JCCIntakeSuccessMessage = ({
  title = "JCC Intake Form Success",
  to = `/jccintakeform`
}: {
  title?: string;
  to?: string;
}) => {
  return (
    <MainWrap>
      <h2>{title}</h2>
      <p>The data was successfully submitted.</p>
      <p>
        To submit another entry, go back to the {title} page by{" "}
        <WmkLink to={to} style={{ display: "inline" }}>
          clicking here
        </WmkLink>
        .
      </p>
    </MainWrap>
  );
};

const JCCIntakeFormSuccessPage = ({
  title,
  to
}: {
  title?: string;
  to?: string;
}) => {
  return (
    <Layout>
      <Container>
        <JCCIntakeSuccessMessage title={"Home Show 2022"} to="/homeshow2022" />
      </Container>
    </Layout>
  );
};

export default JCCIntakeFormSuccessPage;
